import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ValidationInput from '../../inputs/ValidationInput';
import registrationStages from '../constants/registrationStages';
import { formatPhoneNumberForDisplay } from '../../../utilities/formatPhoneNumber';
import { employeeFields as requiredEmployeeFields } from '../constants/requiredFields';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const EmployeeInformation = ({
	newUserConfiguration,
	onConfigurationChange,
	setCurrentStage,
	t
}) => {
	const isFormComplete = () => {
		return requiredEmployeeFields.every((field) => {
			return (
				newUserConfiguration[field] !== undefined &&
				newUserConfiguration[field] !== '' &&
				newUserConfiguration.communicationConsentAccepted !== false
			);
		});
	};
	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' alignItems='center'>
				<BoldLargeHeader py={2}>
					{t('registration:registrationForm.newUserRegistration')}
				</BoldLargeHeader>
			</Stack>
			<Stack direction='column' justifyContent='center' p={2} mx={4} gap={2}>
				<FormControl sx={{ width: '100%' }}>
					<ValidationInput
						label={t('registration:registrationForm.input.firstName')}
						value={newUserConfiguration.firstName}
						onChange={(value) => {
							onConfigurationChange(value, 'firstName');
						}}
						initialValidationErrors={[]}
					/>
				</FormControl>
				<FormControl sx={{ width: '100%' }}>
					<ValidationInput
						label={t('registration:registrationForm.input.lastName')}
						value={newUserConfiguration.lastName}
						onChange={(value) => {
							onConfigurationChange(value, 'lastName');
						}}
						initialValidationErrors={[]}
					/>
				</FormControl>
				<FormControl sx={{ width: '100%' }}>
					<ValidationInput
						label={t('registration:registrationForm.input.phone')}
						value={newUserConfiguration.phone}
						onChange={(value) => {
							onConfigurationChange(value, 'phone');
						}}
						initialValidationErrors={[]}
						displayFormatter={formatPhoneNumberForDisplay}
					/>
				</FormControl>
				<Stack direction='row' alignItems='start' gap={3}>
					<Checkbox
						checked={newUserConfiguration.communicationConsentAccepted}
						onChange={(event) => {
							onConfigurationChange(
								event.target.checked,
								'communicationConsentAccepted'
							);
						}}
					/>
					<Typography fontSize='0.8em'>
						By entering your mobile number, and checking the box, you agree to
						receive operational and maintenance messages from Slyce360 to the
						mobile number you entered. You also agree to the Slyce360 Terms of
						Use. Message & data rates may apply.
					</Typography>
				</Stack>
				<Stack direction='row' justifyContent='center' mt={2}>
					<ContainedButton
						disabled={!isFormComplete()}
						onClick={() => {
							setCurrentStage(registrationStages.slyceInformation);
						}}
					>
						{t('common:buttons.next')}
					</ContainedButton>
				</Stack>
			</Stack>
		</Box>
	);
};

EmployeeInformation.propTypes = {
	newUserConfiguration: PropTypes.object,
	onConfigurationChange: PropTypes.func,
	setCurrentStage: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation(['common', 'registration'])(EmployeeInformation);
